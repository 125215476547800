.react-simple-image-viewer__modal {
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0px 60px 0px 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  box-sizing: border-box;
}

.react-simple-image-viewer__modal-content {
  margin: auto;
  padding: 0;
  width: 90%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}

.react-simple-image-viewer__slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-simple-image-viewer__slide img {
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.react-simple-image-viewer__close {
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  font-weight: bold;
  opacity: .2;
  cursor: pointer;
}

.react-simple-image-viewer__close:hover {
  opacity: 1;
}

.react-simple-image-viewer__previous,
.react-simple-image-viewer__next {
  height: 80%;
  color: white;
  cursor: pointer;
  position: absolute;
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  display: flex;
  align-items: center;
  opacity: .2;
  padding: 0 15px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.react-simple-image-viewer__previous {
  left: 0;
}

.react-simple-image-viewer__next {
  right: 0;
}

.react-simple-image-viewer__previous:hover,
.react-simple-image-viewer__next:hover {
  opacity: 1;
}
